import React from 'react'
import { Layout } from '../components/common'
import {MetaData} from "../components/common/meta";
import {Helmet} from "react-helmet";

/*const IndexPage = () => (
    <Layout>
        <div className="container">
            <article className="content" style={{ textAlign: `center` }}>
                <h1 className="content-title">Paddybasi.com</h1>
                <section className="content-body">
                    Welcome
                </section>
            </article>
        </div>
    </Layout>
)*/

const IndexPage = ({ data, location }) => {

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Layout isHome={ true }>
                <div className="container">
                    <article className="content" style={{ textAlign: `center` }}>
                        <h1 className="content-title">Paddybasi.com</h1>

                        {/* The main page content */}
                        <section className="content-body load-external-scripts">
                            Welcome
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default IndexPage
